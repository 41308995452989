import { gql } from "@apollo/client"
import { POOL_SETTINGS_FRAGMENT } from "../utils/central-queries"

export const ADMIN_DELETE_GAME_INSTANCE_MUTATION = gql`
  mutation adminDeleteGameInstance($mapping: JSON!, $id: ID!) {
    DeleteRecords(mapping: $mapping, id: $id)
  }
`

export const ADMIN_SEASONS_QUERY = gql`
  query AdminSeasonsQuery {
    sql(statement: "SELECT year, seasonType, sportType, MAX(startsAt), MIN(startsAt) FROM Event GROUP BY seasonType, year, sportType")
    byWeek: sql(
      statement: "SELECT year, seasonType, sportType, weekNumber, MAX(startsAt), MIN(startsAt) FROM Event GROUP BY seasonType, year, sportType, weekNumber ORDER BY weekNumber"
    )
  }
`

export const ADMIN_TABLES_QUERY = gql`
  query AdminSQLTablesQuery {
    listTables
  }
`

export const ADMIN_SQL_QUERY = gql`
  query AdminSQLQuery($statement: String!, $ids: [ID!]) {
    sql(statement: $statement, ids: $ids)
  }
`

export const ADMIN_LINE_CHART_QUERY = gql`
  query AdminLineChartQuery($gameInstanceUid: String!, $from: Int, $to: Int, $varient: String) {
    lineChartData(gameInstanceUid: $gameInstanceUid, from: $from, to: $to, varient: $varient)
  }
`

export const ADMIN_ALL_SEGMENTS_QUERY = gql`
  query AdminAllSegmentsQuery {
    allSegments {
      id
      gameType
      sportType
      subsection
      poolSettings {
        ...PoolSettingsFragment
      }
      baseUrl
      tournaments {
        id
        cbsId
      }
      season {
        id
        year
        season
        masterProductId
        productAbbrev
        challengePoolSlug
        gameInstance {
          id
          uid
          state
          client
          description
        }
      }
    }
  }
  ${POOL_SETTINGS_FRAGMENT}
`

// SELECT *, Segment.id AS segmentId FROM Segment LEFT JOIN Season ON Season.id = Segment.seasonId LEFT JOIN GameInstance ON GameInstance.id = Season.gameInstanceId ORDER BY Segment.id DESC

export const ADMIN_REMOVE_EVENT_FROM_SLATE_MUTATION = gql`
  mutation adminRemoveEventFromSlate(
    $periodId: ID!
    $eventId: ID!
    $deletePicks: Boolean
    $deletePeriodEvent: Boolean
    $notificationBannerHtml: String
  ) {
    adminRemoveEventFromSlate(
      periodId: $periodId
      eventId: $eventId
      deletePicks: $deletePicks
      deletePeriodEvent: $deletePeriodEvent
      notificationBannerHtml: $notificationBannerHtml
    )
  }
`

export const ADMIN_LOCK_EVENT_DOWN_MUTATION = gql`
  mutation adminLockEventDown($eventId: ID!, $deleteCBSSpread: Boolean, $deleteGameOdds: Boolean) {
    adminLockEventDown(eventId: $eventId, deleteCBSSpread: $deleteCBSSpread, deleteGameOdds: $deleteGameOdds)
  }
`

export const ADMIN_SWAP_BAD_EVENT_WITH_GOOD_EVENT_MUTATION = gql`
  mutation adminSwapBadEventIdWithGoodEventId($badEventId: ID!, $goodEventId: ID!, $affectedPeriodIds: [ID]) {
    adminSwapBadEventIdWithGoodEventId(badEventId: $badEventId, goodEventId: $goodEventId, affectedPeriodIds: $affectedPeriodIds)
  }
`

export const ADMIN_UPSERT_GAME_INSTANCE_MUTATION = gql`
  mutation adminUpsertGameInstance($mapping: JSON!) {
    UpsertRecords(mapping: $mapping)
  }
`

export const ADMIN_CLOUDWATCH_WIDGET_PNG_QUERY = gql`
  query AdminCloudwatchWidgetPngQuery($widgetDefinition: JSON!, $role: String, $accountId: String) {
    cloudwatchWidgetPng(widgetDefinition: $widgetDefinition, role: $role, accountId: $accountId)
  }
`

export const ADMIN_GAME_INSTANCES_QUERY = gql`
  query AdminGameInstances {
    gameInstances(first: 100) {
      edges {
        node {
          id
          uid
          state
          client
          seasons(first: 100) {
            edges {
              node {
                id
                year
                season
                whenToWatch {
                  id
                  periodName
                  dates
                }
                segments(first: 100) {
                  edges {
                    node {
                      id
                      gameType
                      sportType
                      baseUrl
                      landingUrl
                      tournaments {
                        id
                        cbsId
                      }
                    }
                  }
                }
              }
            }
          }
          currentPeriod {
            id
            description
            endsAt
            isCurrent
            isPickable
            segment {
              id
              gameType
              sportType
              subsection
              baseUrl
              landingUrl
              season {
                id
                year
                season
                masterProductId
                challengePoolSlug
              }
            }
          }
        }
      }
    }
    sql(
      statement: "SELECT year, seasonType, sportType, MAX(startsAt), MIN(startsAt) FROM Event GROUP BY seasonType, year, sportType ORDER BY MAX(startsAt)"
    )
    byWeek: sql(
      statement: "SELECT year, seasonType, sportType, weekNumber, MAX(startsAt), MIN(startsAt) FROM Event GROUP BY seasonType, year, sportType, weekNumber ORDER BY weekNumber"
    )
  }
`

export const ADMIN_MAINT_POOLS_QUERY = gql`
  query AdminSQLMaintPoolsQuery($poolId: ID!) {
    pool(id: $poolId) {
      id
      name
      isArchived
      gameInstanceUid
    }
  }
`

export const ADMIN_SCORE_BRACKET_POOL_MUTATION = gql`
  mutation AdminScoreBracketPoolMutation($poolId: ID!, $scoreFullSeason: Boolean) {
    adminScoreBracketPool(poolId: $poolId, scoreFullSeason: $scoreFullSeason)
  }
`

export const ADMIN_SCORE_OPM_POOL_MUTATION = gql`
  mutation AdminScoreOPMPoolMutation($poolId: ID!, $scoreFullSeason: Boolean) {
    adminScoreOPMPool(poolId: $poolId, scoreFullSeason: $scoreFullSeason)
  }
`

export const ADMIN_DELETE_POOL_PICKS_MUTATION = gql`
  mutation AdminDeletePoolPicksMutation($poolId: ID!) {
    adminDeletePoolPicks(poolId: $poolId)
  }
`

export const ADMIN_GET_POOL_MEMBERS = gql`
  query AdminGetPoolMembers($poolId: ID!) {
    pool(id: $poolId) {
      id
      url
      adminMembers {
        member {
          id
          name
          email
          isManager
        }
        user {
          id
          custId
          picksDbId
        }
      }
    }
  }
`

export const ADMIN_MAINT_POOL_ENTRY_QUERY = gql`
  query AdminSQLMaintEntryQuery($entryId: ID!) {
    entry(id: $entryId) {
      name
      id
      roles
      poolId
    }
  }
`

export const ADMIN_SCORE_POOL_MUTATION = gql`
  mutation AdminScorePool($poolId: ID!) {
    scorePoolJob(poolId: $poolId, scoreFullSeason: true)
  }
`

export const ADMIN_RESTORE_ENTRY_MUTATION = gql`
  mutation AdminRestoreEntry($entryData: JSON!, $activityDbId: Int) {
    restoreEntry(entryData: $entryData, activityDbId: $activityDbId)
  }
`

export const ADMIN_ARCHIVE_POOL_MUTATION = gql`
  mutation AdminArchivePool($poolId: ID!) {
    archivePoolJob(poolId: $poolId, forceFullyArchive: true)
  }
`

export const ADMIN_RESTORE_POOL_MUTATION = gql`
  mutation AdminRestorePool($poolId: ID!) {
    restorePoolJob(poolId: $poolId, forceFullRestore: true)
  }
`

export const ADMIN_SERVICE_SCALING_QUERY = gql`
  query AdminServiceScalingQuery {
    hostingServiceScalingParemeters {
      id
      name
      max
      min
      expiresAt
    }
  }
`
export const ADMIN_SET_SERVICE_SCALING_MUTATION = gql`
  mutation AdminSetServiceScalingMutation($values: [HostingServiceScalingParametersInput!]!) {
    setHostingServiceScalingParameters(values: $values) {
      id
      name
      max
      min
      expiresAt
    }
  }
`

export const ADMIN_REDIS_SCAN_QUERY = gql`
  query AdminRedisScanQuery($cursor: ID, $pattern: String) {
    redisScan(cursor: $cursor, pattern: $pattern) {
      cursor
      keys
      hasNext
    }
  }
`

export const ADMIN_REDIS_KEY_QUERY = gql`
  query AdminRedisKeyQuery($key: String!) {
    redisKey(key: $key) {
      key
      value
      ttl
    }
  }
`

export const ADMIN_REDIS_SET_KEY_MUTATION = gql`
  mutation AdminRedisSetKeyMutation($key: String!, $value: String!, $ttl: Int) {
    setRedisKey(key: $key, value: $value, ttl: $ttl) {
      key
      value
      ttl
    }
  }
`

export const ADMIN_REDIS_UNLINK_KEY_MUTATION = gql`
  mutation AdminRedisUnlinkKeyMutation($key: String!) {
    unlinkRedisKey(key: $key)
  }
`
export const ADMIN_REDIS_FLUSH_DB = gql`
  mutation AdminRedisFlushDb {
    flushRedisDb
  }
`

export const ADMIN_UPDATE_WHEN_TO_WATCH = gql`
  mutation AdminUpdateWhenToWatch($seasonId: String!, $data: [WhenToWatchDataInputType!]) {
    upsertSeasonWhenToWatch(seasonId: $seasonId, data: $data) {
      periodName
      dates
    }
  }
`

export const ADMIN_UPDATE_BRACKET_STATE_MUTATION = gql`
  mutation AdminUpdateBracketStateMutation($segmentId: String!, $state: Float!) {
    UpdateBracketState(segmentId: $segmentId, state: $state)
  }
`

export const ADMIN_DELETE_USER_MUTATION = gql`
  mutation AdminDeleteUserMutation($custId: Int!) {
    adminDeleteUser(custId: $custId)
  }
`

export const ADMIN_UPSERT_NEWSLETTER_MUTATION = gql`
  mutation AdminUpsertNewsletterMutation($id: ID, $data: NewsletterSubscriptionInputType!) {
    upsertNewsletterSubscription(id: $id, data: $data) {
      id
      sportType
      gameInstanceUid
      title
      options {
        id
        text
        mCode
      }
    }
  }
`
// export const ADMIN_UPDATE_NEWSLETTER_SUBSCRIPTIONS_QUERY = gql`
//   query adminUpdateNewsletterSubscriptions {

//   }
// `

export const SCORE_OPC = gql`
  mutation ScoreOPC($scoreFullSeason: Boolean) {
    scoreOpc(scoreFullSeason: $scoreFullSeason)
  }
`

export const SCORE_FFBOPC = gql`
  mutation ScoreFFBOPC($scoreFullSeason: Boolean) {
    scoreFFBOpc(scoreFullSeason: $scoreFullSeason)
  }
`

export const ADMIN_GET_SEASONS_TO_SIMULATE = gql`
  query AdminGetSeasonsToSimulate {
    adminGetSeasonsToSimulate {
      id
      productAbbrev
      gameInstance {
        id
      }
    }
  }
`

export const ADMIN_GET_SEASONS_BEING_SIMULATED = gql`
  query AdminGetSeasonsBeingSimulated {
    adminGetSeasonsBeingSimulated {
      id
      productAbbrev
      currentSimulatedState {
        id
        seasonId
        state
        description
        isCurrent
      }
      gameInstance {
        id
        uid
        currentPeriod {
          id
          order
        }
      }
    }
  }
`

export const ADMIN_SET_SEASON_TO_SIMULATE = gql`
  mutation AdminSetSeasonToSimulate($seasonId: ID!) {
    adminSetSeasonToSimualate(seasonId: $seasonId)
  }
`

export const ADMIN_UNSET_SEASON_FROM_SIMULATION = gql`
  mutation AdminUnsetSeasonFromSimulation($seasonId: ID!) {
    adminUnsetSeasonFromSimulation(seasonId: $seasonId)
  }
`

export const ADMIN_ADVANCE_SIMULATION_STATE = gql`
  mutation AdminAdvanceSimulationState($seasonId: ID!) {
    adminAdvanceSimulationState(seasonId: $seasonId)
  }
`

export const ADMIN_RESET_SIMULATION_STATE = gql`
  mutation AdminResetSimulationState($seasonId: ID!) {
    adminResetSimulationState(seasonId: $seasonId)
  }
`

export const ADMIN_MAKE_PICKS_FOR_SIMULATED_SEASON = gql`
  mutation AdminMakePicksForSimulatedSeason($seasonId: ID!) {
    adminMakePicksForSimulatedSeason(seasonId: $seasonId)
  }
`
